import api from './api.service';

export default class UserService {
    
    changePassword = (current_password, new_password) => {
        return api().put('api/u/me/password', {current_password, new_password});
    }

    deleteProfileCV = () => {
        return api().delete('api/u/me/cv');
    }

    deleteTrait = (trait_type, trait_id) => {
        return api().delete(`api/u/me/${trait_type}/${trait_id}`);
    }

    getApplications = (params) => {
        const _params = new URLSearchParams(params);
        return api().get('api/u/applications?' + _params.toString()).then(resp=>resp.data);
    }

    getCities = () => {
        return api().get("api/cities")
    }

    getCommonList = (params) => {
        const _params = new URLSearchParams(params);
        return api().get('api/common_list?' + _params.toString()).then(resp=>resp.data);
    }

    getCsrf = ()=>{
        return api().get('/sanctum/csrf-cookie');
    }

    getProfile = ()=>{
        return api().get('api/u/me').then(resp=>resp.data);
    }

    insertTrait = (trait_type, params) => {
        return api().post(`api/u/me/${trait_type}`, params);
    }

    login = (email, password) => {
        return api().post('login', {email, password});
    }

    logOut = () => {
        return api().get("logout");
    }

    recoveryPassword(new_password, request_token, user_type){
        return api().post('api/u/recovery-password', {new_password, request_token, user_type});
    }

    register = (name, lastname, email, password) => {
        return api().post('register', {name, lastname, email, password});
    }

    sendRecoveryPasswordLink = (email) => {
        return api().post('api/u/reset-password', {email});
    }

    updateProfile = (params) => {
        return api().put('api/u/me', params);
    }

    updateProfileCV = (formData)=>{
        return api().post('api/u/me/cv', formData);
    }

    updateProfilePic = (formData)=>{
        return api().post('api/u/me/profile-pic', formData);
    }
    
    updateTrait = (trait_type, trait_id, params) => {
        return api().put(`api/u/me/${trait_type}/${trait_id}`, params);
    }

}
