import Cookies from 'js-cookie';
import cookie from 'cookie';

export const isLoggedIn = (reqCookies = null) => {
    if(!reqCookies)
        return !! Cookies.get('elitec_is_user_logged_in');

    return !! cookie.parse(reqCookies).elitec_is_user_logged_in
} 

export const logIn = (user)=>{
    localStorage.setItem("elitec_user", JSON.stringify(user));
    Cookies.set('elitec_is_user_logged_in', true, {expires: 86400, sameSite: 'lax'})
    window.location.href = "/";
}

export const logOut = ()=>{
    if(typeof window !== 'undefined'){
        localStorage.removeItem("elitec_user");
        Cookies.remove('elitec_is_user_logged_in', {expires: 86400, sameSite: 'lax'});
        window.location.href = "/signin";
    }
}