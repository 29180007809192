import React, { Suspense, useEffect } from "react";

//Importing Section
// const TopBar = import("../CommonLayout/TopBar");
import TopBar from "../CommonLayout/TopBar";
import NavBar from "../CommonLayout/NavBar";
import Subscribe from "../CommonLayout/Subscribe";
import Footer from "../CommonLayout/Footer";
import ScrolltoTop from "../../components/ScrolltoTop";
import { isLoggedIn } from "../../services/auth.service";
import UserService from "../../services/user.service";
import UserStore from "../../stores/user.store";
import CommonStore from "../../stores/common.store";

const Layout = (props) => {

    useEffect(()=>{
        let u = new UserService();
        if( isLoggedIn() ){
            u.getProfile().then(resp=>{
                UserStore.updateUser(resp);
            })
        }

        u.getCommonList({with_cities: 1, with_education_levels: 1, with_languages: 1, with_job_types: 1}).then(resp=>{
            CommonStore.update(resp);
        });
    }, []);

    return (
        <React.Fragment>
            <Suspense>
                <div>
                    <div className="w-100 position-fixed" style={{zIndex: 1030, top: 0}}>
                        <TopBar />
                        <NavBar />
                    </div>
                    <div className="main-content">
                        <div className="page-content">{props.children}</div>
                    </div>
                    <Subscribe />
                    <ScrolltoTop />
                    <Footer />
                </div>
            </Suspense>
        </React.Fragment>
    );
};

export default Layout;