import React, { useState, useEffect, useLayoutEffect } from "react";
import {
    Container,
    Collapse,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";

import { Link, withRouter } from "react-router-dom";

import darkLogo from "../../assets/images/logo-dark.png";
import lightLogo from "../../assets/images/logo-light.png";
import profileImage from "../../assets/images/perfil.png";

import UserService from "../../services/user.service";
import { logOut } from "../../services/auth.service";
import UserStore from "../../stores/user.store";

const NavBar = (props) => {

    const [userState, setUserState] = useState(null);
    useEffect(()=>{
        UserStore.currentUser.subscribe({
            next: user=>{
                setUserState(user);
            }
        })
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    /*
    //Notification Dropdown
    const [notification, setNotification] = useState(false);
    const dropDownnotification = () => setNotification((prevState) => !prevState);
    */

    //user Profile Dropdown
    const [userProfile, setUserProfile] = useState(false);
    const dropDownuserprofile = () => setUserProfile((prevState) => !prevState);

    const doLogOut = () => {
        let s = new UserService();
        s.logOut().then(resp=>{
            logOut();
        });
    }

    return (
        <React.Fragment>
            <nav className={"navbar navbar-expand-lg p-0"} id="navigation">
                <Container fluid className="custom-container">
                    <div>
                        <NavbarToggler className="me-3" type="button" onClick={() => toggle()}>
                            <i className="mdi mdi-menu"></i>
                        </NavbarToggler>
                    </div>
                    <Link className="navbar-brand text-dark fw-bold me-auto" to="/">
                        <img src={darkLogo} height="22" alt="Elitec logo" className="logo-dark" />
                        <img src={lightLogo} height="22" alt="Elitec logo" className="logo-light" />
                    </Link>
                    <Collapse isOpen={isOpen} className="navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav mx-auto navbar-center">                            
                            <NavItem>
                                <Link className="nav-link" to="/">Inicio</Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link" to="/joblist">Ofertas de trabajo</Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link" to="/companylist">Empresas buscandote</Link>
                            </NavItem> 
                            <NavItem>
                                <Link className="nav-link" to="/blog">Blog</Link>
                            </NavItem>                            
                        </ul>
                    </Collapse>

                    <ul className="header-menu list-inline d-flex align-items-center mb-0">
                        {
                            /*
                            <Dropdown isOpen={notification}
                                    toggle={dropDownnotification} className="list-inline-item  me-4">
                                    <DropdownToggle href="#" className="header-item noti-icon position-relative" id="notification" type="button" tag="a"
                                    >
                                        <i className="mdi mdi-bell fs-22"></i>
                                        <div className="count position-absolute">3</div>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-sm dropdown-menu-end p-0" aria-labelledby="notification" end>
                                        <div className="notification-header border-bottom bg-light">
                                            <h6 className="mb-1"> Notificaciones </h6>
                                            <p className="text-muted fs-13 mb-0">Tienes 4 notificaciones sin leer</p>
                                        </div>
                                        <div className="notification-wrapper dropdown-scroll">
                                            <Link to="#" className="text-dark notification-item d-block active">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                                                            <i className="uil uil-user-check"></i>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mt-0 mb-1 fs-14">22 verified registrations</h6>
                                                        <p className="mb-0 fs-12 text-muted"><i className="mdi mdi-clock-outline"></i> <span>3 min
                                                            ago</span></p>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="#" className="text-dark notification-item d-block">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <img src={userImage2} className="rounded-circle avatar-xs" alt="user-pic" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mt-0 mb-1 fs-14">James Lemire</h6>
                                                        <p className="text-muted fs-12 mb-0"><i className="mdi mdi-clock-outline"></i> <span>15 min
                                                            ago</span></p>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="#" className="text-dark notification-item d-block">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <img src={jobImage4} className="rounded-circle avatar-xs"
                                                            alt="user-pic" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mt-0 mb-1 fs-14">Applications has been approved</h6>
                                                        <p className="text-muted mb-0 fs-12"><i className="mdi mdi-clock-outline"></i> <span>45 min
                                                            ago</span></p>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="#" className="text-dark notification-item d-block">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <img src={userImage1} className="rounded-circle avatar-xs"
                                                            alt="user-pic" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mt-0 mb-1 fs-14">Kevin Stewart</h6>
                                                        <p className="text-muted mb-0 fs-12"><i className="mdi mdi-clock-outline"></i> <span>1 hour
                                                            ago</span></p>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="#" className="text-dark notification-item d-block">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <img src={jobImage} className="rounded-circle avatar-xs"
                                                            alt="user-pic" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mt-0 mb-1 fs-15">Creative Agency</h6>
                                                        <p className="text-muted mb-0 fs-12"><i className="mdi mdi-clock-outline"></i> <span>2 hour
                                                            ago</span></p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="notification-footer border-top text-center">
                                            <Link className="primary-link fs-13" to="#">
                                                <i className="mdi mdi-arrow-right-circle me-1"></i> <span>Ver más..</span>
                                            </Link>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>   
                            */
                        }
                        {
                            userState?.id>0 && <Dropdown
                                onClick={() => setUserProfile(!userProfile)}
                                isOpen={userProfile}
                                toggle={dropDownuserprofile}
                                className="list-inline-item">
                                <DropdownToggle to="#" className="header-item d-flex align-items-center" id="userdropdown" type="button" tag="a"
                                    aria-expanded="false">
                                    <img src={ userState?.url || profileImage} alt="mdo" width="35" height="35" className="rounded-circle me-1" /> <span className="d-none d-md-inline-block fw-medium">Hola, { userState ? userState.name : 'John Doe' }</span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end" aria-labelledby="userdropdown" end>
                                    {
                                        /*
                                        <li><Link className="dropdown-item" to="/bookmarkjobs">Trabajos favoritos </Link></li>
                                        */
                                    }
                                    <li><Link className="dropdown-item" to="/myprofile">Mi Perfil</Link></li>
                                    <li><Link className="dropdown-item" to="/myapplications">Mis Postulaciones</Link></li>
                                    <li><a href="#" className="dropdown-item" onClick={()=>{doLogOut()}}>Cerrar sesión</a></li>
                                </DropdownMenu>
                            </Dropdown>
                        }
                        {
                            userState?.id < 1 && <li><Link to="/signin">Iniciar Sesión</Link></li>
                        }
                    </ul>
                </Container>
            </nav>
        </React.Fragment>
    );
};

export default withRouter(NavBar);
