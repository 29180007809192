import React from "react";
import Routes from "./Routes/index";

//import Custom Style scss
import "./assets/scss/themes.scss";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <React.Fragment>
      <Routes />
      <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover={false}
            />
    </React.Fragment>
  );
}

export default App;
