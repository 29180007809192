import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  Input,  
  Modal,
  ModalBody,  
  Form,
  FormGroup,
  Label
} from "reactstrap";
import { Link } from "react-router-dom";
import { isLoggedIn } from "../../services/auth.service";

const TopBar = () => {
  const iconTobar = [
    {
      id: 1,
      classname: "uil uil-whatsapp",
    },
    {
      id: 2,
      classname: "uil uil-facebook-messenger-alt",
    },
    {
      id: 3,
      classname: "uil uil-instagram",
    },
    {
      id: 4,
      classname: "uil uil-envelope",
    },
    {
      id: 5,
      classname: "uil uil-twitter-alt",
    },
  ];


  //Signup Modal
  const [modal, setModal] = useState(false);

  const openModal = () => setModal(!modal);
  
  return (
    <React.Fragment>
      <div className="top-bar" style={{zIndex: 1030}}>
        <Container fluid className="custom-container">
          <Row className="g-0 align-items-center">
            <Col md={7}>
              <ul className="list-inline mb-0 text-center text-md-start">
                <li className="list-inline-item">
                  <p className="fs-13 mb-0">
                    {" "}
                    <i className="mdi mdi-map-marker"></i> Tú ubicación:{" "}
                    <Link to="#" className="text-dark">
                      Lima
                    </Link>
                  </p>
                </li>
                <li className="list-inline-item">
                  <ul className="topbar-social-menu list-inline mb-0">
                    {(iconTobar || []).map((icon, key) => (
                      <li className="list-inline-item" key={key}>
                        <Link to="/" className="social-link">
                          <i className={icon.classname}></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </Col>
            {
              !isLoggedIn() && <Col md={5}>
                <ul className="list-inline mb-0 text-center text-md-end">
                  <li className="list-inline-item py-2 me-2 align-middle">
                    <Link
                      to="/signup"
                      role="button"
                      className="text-dark fw-medium fs-13"
                    >
                      <i className="uil uil-lock"></i>
                      Regístrate
                    </Link>
                    <Modal isOpen={modal} toggle={openModal} role="dialog" centered>
                      <ModalBody className="p-5">
                        <div className="position-absolute end-0 top-0 p-3">
                          <button
                            type="button"
                            className="btn-close"
                            onClick={openModal}                        
                          ></button>
                        </div>
                        <div className="auth-content">
                          <div className="w-100">
                            <div className="text-center mb-4">
                              <h5>Crea tu cuenta</h5>
                              <p className="text-muted">
                                Regístrese y acceda a mas de 10k ofertas de trabajo
                              </p>
                            </div>
                            <Form action="#" className="auth-form">
                            
                              <FormGroup className="mb-3">
                                <Label
                                  htmlFor="usernameInput"
                                  className="form-label"
                                >
                                  Usuario
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="usernameInput"
                                  placeholder="Enter your username"
                                />
                              </FormGroup>
                              <FormGroup className="mb-3">
                                <Label
                                  htmlFor="emailInput"
                                  className="form-label"
                                >
                                  Correo electrónico
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="emailInput"
                                  placeholder="Escribe tu correo"
                                />
                              </FormGroup>
                              <FormGroup className="mb-3">
                                <label htmlFor="passwordInput" className="form-label">
                                  Contraseña
                                </label>
                                <Input
                                  type="password"
                                  className="form-control"
                                  id="passwordInput"
                                  placeholder="Escribe tu contraseña"
                                />
                              </FormGroup>
                              <FormGroup className="mb-4">
                                <div className="form-check">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexCheckDefault"
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    Estoy de acuerdo con los{" "}
                                    <Link
                                      to="/"
                                      className="text-primary form-text text-decoration-underline"
                                    >
                                      términos y condiciones
                                    </Link>
                                  </Label>
                                </div>
                              </FormGroup>
                              <div className="text-center">
                                <button
                                  type="submit"
                                  className="btn btn-primary w-100"
                                >
                                  Crear cuenta
                                </button>
                              </div>
                            </Form>
                            <div className="mt-3 text-center">
                              <p className="mb-0">
                                Ya eres usuario?{" "}
                                <Link
                                  to="/signin"
                                  className="form-text text-primary text-decoration-underline"
                                >
                                  {" "}
                                  Iniciar sesión{" "}
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                  </li>
                </ul>
              </Col>
            }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TopBar;
