import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { Link } from "react-router-dom";

import lightLogo from "../../assets/images/logo-light.png";

const Footer = () => {
    const footer = [
        {
            id: 1,
            title: "Área",
            menu: [
                {
                    id: 1,
                    link: "/joblist",
                    subTitle: "Tecnológia"
                },
                {
                    id: 2,
                    link: "/joblist",
                    subTitle: "Administración"
                },
                {
                    id: 3,
                    link: "/joblist",
                    subTitle: "Contabilidad"
                },
                {
                    id: 4,
                    link: "/joblist",
                    subTitle: "Negocios"
                },
            ]
        },
        {
            id: 2,
            title: "Tipo de trabajo",
            menu: [
                {
                    id: 1,
                    link: "/joblist",
                    subTitle: "Part Time"
                },
                {
                    id: 2,
                    link: "/joblist",
                    subTitle: "Full Time"
                },
                {
                    id: 3,
                    link: "/joblist",
                    subTitle: "Presencial"
                },
                {
                    id: 4,
                    link: "/joblist",
                    subTitle: "Remoto"
                },
            ]
        },
        {
            id: 3,
            title: "Experiencia",
            menu: [
                {
                    id: 1,
                    link: "/joblist",
                    subTitle: "No experiencia"
                },
                {
                    id: 2,
                    link: "/joblist",
                    subTitle: "0-3 años"
                },
                {
                    id: 3,
                    link: "/joblist",
                    subTitle: "3-6 años"
                },
                {
                    id: 4,
                    link: "/joblist",
                    subTitle: "Más de 6 años"
                }
            ]
        },
        {
            id: 4,
            title: "Ayuda",
            menu: [
                {
                    id: 1,
                    link: "/blog",
                    subTitle: "Blog"
                },
                {
                    id: 2,
                    link: "/faqs",
                    subTitle: "Soporte"
                },
                {
                    id: 3,
                    link: "/privacyandpolicy",
                    subTitle: "T. y Condiciones"
                },
                {
                    id: 4,
                    link: "/privacyandpolicy",
                    subTitle: "Política de Privacidad"
                }
            ]
        },
    ];
    const footerIcons = [
        {
            id: 1,
            socialIcon: "uil uil-facebook-f",
        },
        {
            id: 2,
            socialIcon: "uil uil-linkedin-alt",
        },
        {
            id: 3,
            socialIcon: "uil uil-instagram",
        },
    ];
    return (
        <React.Fragment>
            <section className="bg-footer">
                <Container>
                    <Row>
                        <Col lg={4}>
                            <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                                <img src={lightLogo} height="50" alt="Elitec logo" className="logo-light mb-4" />
                                <p className="text-white-50">Av. Paseo de la República Nro. 5045 Surquillo, Lima – Perú</p>
                                <p className="text-white mt-3">Siguenos:</p>
                                <ul className="footer-social-menu list-inline mb-0" >
                                    {(footerIcons).map((footerIcondetails, key) => (
                                        <li className="list-inline-item" key={key}>
                                            <Link to="#"><i className={footerIcondetails.socialIcon}></i></Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        {(footer).map((footerdetails, key) => (
                            <Col lg={2} xs={6} key={key}>
                                <div className="footer-item mt-4 mt-lg-0">
                                    <p className="fs-16 text-white mb-4">{footerdetails.title}</p>
                                    {(footerdetails.menu || []).map((menuInner, key) => (
                                        <ul className="list-unstyled footer-list mb-0" key={key}>
                                            <li><Link to={menuInner.link}><i className="mdi mdi-chevron-right"></i> {menuInner.subTitle}</Link></li>
                                        </ul>
                                    ))}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <div className="footer-alt">
                <Container >
                    <Row >
                        <Col lg={12}>
                            <p className="text-white-50 text-center mb-0">
                                {new Date().getFullYear()} &copy; <Link to="//elitec.edu.pe/" target="_blank"
                                    className="text-reset">ELITEC.</Link>
                                    Todos Los Derechos Reservados.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default Footer;
