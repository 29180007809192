import React from "react";
/** Add Route Component */
//Home Section
const Layout1 =  React.lazy(() => import("../pages/Home/Layout1/Layout1"));
/*
const Layout2 =  React.lazy(() => import("../pages/Home/Layout2/Layout2"));
const Layout3 =  React.lazy(() => import("../pages/Home/Layout3/Layout3"));


//Company Section
const AboutUs =  React.lazy(() => import("../pages/Company/AboutUs/AboutUs"));
const Services =  React.lazy(() => import("../pages/Company/Services/Services"));
const Team =  React.lazy(() => import("../pages/Company/Team/Team"));
const Pricing =  React.lazy(() => import("../pages/Company/Pricing/Pricing"));
const PrivacyAndPolicy =  React.lazy(() => import("../pages/Company/PrivacyAndPolicy/PrivacyAndPolicy"));
const Faqs =  React.lazy(() => import("../pages/Company/Faqs/Faqs"));
*/

//Jobs Section
const JobList =  React.lazy(() => import("../pages/JobList/JobList"));
const Job =  React.lazy(() => import("../pages/Job/Job"));

/*
const JobList2 =  React.lazy(() => import("../pages/Jobs/JobList2/JobList2"));
const JobGrid =  React.lazy(() => import("../pages/Jobs/JobGrid/JobGrid"));
const JobGrid2 =  React.lazy(() => import("../pages/Jobs/JobGrid2/JobGrid2"));

const JobsCategories =  React.lazy(() => import("../pages/Jobs/JobsCategories/JobsCategories"));

//Candidate and Company Section
const CandidateList =  React.lazy(() => import("../pages/CandidateAndCompany/CandidateList/CandidateList"));
const CandidateGrid =  React.lazy(() => import("../pages/CandidateAndCompany/CandidateGrid/CandidateGrid"));
const CandidateDetails =  React.lazy(() => import("../pages/CandidateAndCompany/CandidateDetails/CandidateDetails"));
*/

const CompanyList =  React.lazy(() => import("../pages/CompanyList/CompanyList"));
const CompanyDetails =  React.lazy(() => import("../pages/CompanyDetails/CompanyDetails"));

//Blog Section
const Blog =  React.lazy(() => import("../pages/Blog/Blog/Blog"));
/*
const BlogGrid =  React.lazy(() => import("../pages/Blog/BlogGrid/BlogGrid"));
const BlogModern =  React.lazy(() => import("../pages/Blog/BlogModern/BlogModern"));
const BlogMasonary =  React.lazy(() => import("../pages/Blog/BlogMasonary/BlogMasonary"));
const BlogDetails =  React.lazy(() => import("../pages/Blog/BlogDetails/BlogDetails"));
const BlogAuther =  React.lazy(() => import("../pages/Blog/BlogAuther/BlogAuther"));

//const Contacts 
const Contact =  React.lazy(() => import("../pages/Contact/Contact"));
*/

//const AuthPages
const SignIn =  React.lazy(() => import("../pages/SignIn/SignIn"));
const SignUp =  React.lazy(() => import("../pages/SignUp/SignUp"));
const ResetPassword =  React.lazy(() => import("../pages/ResetPassword/ResetPassword"));
const RecoveryPassword =  React.lazy(() => import("../pages/RecoveryPassword/RecoveryPassword"));
const Error404 =  React.lazy(() => import("../pages/ExtraPages/Error404"));

/*
const SignOut =  React.lazy(() => import("../pages/SignOut/SignOut"));
const ComingSoon =  React.lazy(() => import('../pages/ExtraPages/ComingSoon'));

const Components =  React.lazy(() => import("../pages/ExtraPages/Components/Components"));

//profile section(User Profile)
const BookMarkJobPost =  React.lazy(() => import("../pages/Profile/BookMarkJobPost/BookMarkJobPost"));
const ManageJobs =  React.lazy(() => import("../pages/Profile/ManageJobs/ManageJobs"));
*/

const BookMarkJobs =  React.lazy(() => import("../pages/Profile/BookMarkJobs/BookMarkJobs"));
const MyProfile =  React.lazy(() => import("../pages/Profile/MyProfile/MyProfile"));
const Applications =  React.lazy(() => import("../pages/Applications/Applications"));

const userRoutes = [

  { path: "/myprofile", component: MyProfile   },
  { path : "/company/:company_id", component: CompanyDetails },
  { path : "/companylist", component: CompanyList },
  { path : "/joblist", component: JobList },
  { path : "/job/:job_id", component: Job },
  { path : "/", component: Layout1 },
  { path : "/blog", component: Blog },
  { path: "/bookmarkjobs", component: BookMarkJobs },
  { path: "/myapplications", component: Applications },

  /*
  //profile Section(User Profile)
  { path: "/bookmarkjobpost", component: BookMarkJobPost },
  
  { path: "/managejobs", component: ManageJobs },

  //Components Section(Extra Pages)
  { path: "/components", component: Components },

  //Contact
  { path : "/contact", component: Contact },
  */

  /*
  // Blog Section
  { path : "/blogauther", component: BlogAuther },
  { path : "/blogdetails", component: BlogDetails },
  { path : "/blogmodern", component: BlogModern },
  { path : "/blogmasonary", component: BlogMasonary },
  { path : "/bloggrid", component: BlogGrid },
  { path : "/blog", component: Blog },
  */

  //Candidate and Company Section


  /*
  { path : "/candidatedetails", component: CandidateDetails },
  { path : "/candidategrid", component: CandidateGrid },
  { path : "/candidatelist", component: CandidateList },
  */

  /*
  //Jobs Section
  { path : "/jobscategories", component: JobsCategories },
  { path : "/jobdetails", component: JobDetails },
  { path : "/jobgrid2", component: JobGrid2 },
  { path : "/jobgrid", component: JobGrid },
  { path : "/joblist2", component: JobList2 },
  { path : "/joblist", component: JobList },

  //Company Section  
  { path : "/faqs", component: Faqs },
  { path : "/privacyandpolicy", component: PrivacyAndPolicy },
  { path : "/pricing", componen|t: Pricing },
  { path : "/team", component: Team },
  { path : "/services", component: Services },
  { path : "/aboutus", component: AboutUs },
  */

  /*
  //Home Section
  { path : "/layout3", component: Layout3 },
  { path : "/layout2", component: Layout2 },
  */

];

const authRoutes = [
  /*
  { path: "/error404", component: Error404 },
  { path: "/comingsoon", component: ComingSoon }, 
  { path: "/signout", component: SignOut },
  */ 
  { path: "/error404", component: Error404 },
  { path: "/resetpassword", component: ResetPassword }, 
  { path: "/signup", component: SignUp },  
  { path: "/signin", component: SignIn },
  { path: "/recovery-password", component: RecoveryPassword },
];
export { userRoutes, authRoutes };
