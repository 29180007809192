import { BehaviorSubject } from 'rxjs';

const initialState = {id:0};
const subject = new BehaviorSubject(initialState);
const currentUser = subject.asObservable();

const UserStore = {
    currentUser: currentUser,
    updateUser: user=>{
        subject.next({...subject.getValue(), ...user});
    },
    initialState
}

export default UserStore;