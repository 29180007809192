import axios from 'axios';
import { logOut } from './auth.service';

export default function api(){

    const api = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        withCredentials: true
    });

    api.interceptors.response.use(response => response, error => {
        if (error.response.status === 401) {
            logOut();
            return Promise.reject();
        } else if (error.response.status === 404){
            window.location.href = "/error404";
            return Promise.reject();
        }

        return Promise.reject(error);
    });

    return api;
}