import { BehaviorSubject } from 'rxjs';

const initialState = {id:0};
const subject = new BehaviorSubject(initialState);
const current = subject.asObservable();

const CommonStore = {
    current: current,
    update: common=>{
        subject.next({...subject.getValue(), ...common});
    },
    initialState
}

export default CommonStore;